import _acornNode from "acorn-node";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var aparse = _acornNode.parse;

function parse(src, opts) {
  if (!opts) opts = {};
  return aparse(src, opts);
}

exports = function (src, file, opts) {
  if (typeof src !== "string") src = String(src);

  try {
    eval("throw \"STOP\"; (function () { " + src + "\n})()");
    return;
  } catch (err) {
    if (err === "STOP") return undefined;
    if (err.constructor.name !== "SyntaxError") return err;
    return errorInfo(src, file, opts);
  }
};

function errorInfo(src, file, opts) {
  try {
    parse(src, opts);
  } catch (err) {
    return new ParseError(err, src, file);
  }

  return undefined;
}

function ParseError(err, src, file) {
  SyntaxError.call(this || _global);
  (this || _global).message = err.message.replace(/\s+\(\d+:\d+\)$/, "");
  (this || _global).line = err.loc.line;
  (this || _global).column = err.loc.column + 1;
  (this || _global).annotated = "\n" + (file || "(anonymous file)") + ":" + (this || _global).line + "\n" + src.split("\n")[(this || _global).line - 1] + "\n" + Array((this || _global).column).join(" ") + "^" + "\n" + "ParseError: " + (this || _global).message;
}

ParseError.prototype = Object.create(SyntaxError.prototype);

ParseError.prototype.toString = function () {
  return (this || _global).annotated;
};

ParseError.prototype.inspect = function () {
  return (this || _global).annotated;
};

export default exports;